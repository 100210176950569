<template>
  <section class="paper-view">
    <el-form label-width="90px" style="min-width:700px">
      <el-form-item label="学生/教研">
        <el-select v-model="paperMode" placeholder="请选择">
          <el-option v-for="item in paperModeOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="paper" v-if="paperMode != 'student'">
      {{ paper.name }} <br />
      <section v-for="(chapter, cIndex) in paper.chapters" :key="cIndex">
        {{ chapter.name }}: {{ chapter.desc }} <br />
        <div class="chapter">
          <div class="question" v-for="(ques, qIndex) in chapter.questionList" :key="qIndex">
            <questionViewWidget
              :tikuQuestion="ques"
              :showAnswer="true"
              :showQuestion="true"
              :qIndex="ques.questionIndex"
            ></questionViewWidget>
            <div v-if="ques.questionMetaHtml" v-html="ques.questionMetaHtml"></div>
            <br />
          </div>
        </div>
        <br />
      </section>
    </div>
    <div class="paper" v-else>
      {{ paper.name }} <br />
      <section v-for="(chapter, cIndex) in paper.chapters" :key="cIndex">
        {{ chapter.name }}: {{ chapter.desc }} <br />
        <div class="chapter">
          <div class="question" v-for="(ques, qIndex) in chapter.questionList" :key="qIndex">
            <questionViewWidget
              :tikuQuestion="ques"
              :showAnswer="false"
              :showQuestion="true"
              :qIndex="ques.questionIndex"
            ></questionViewWidget>
            <br />
          </div>
        </div>
        <br />
      </section>
      <h1>参考解析</h1>
      <section v-for="(chapter, cIndex) in paper.chapters" :key="'a' + cIndex">
        {{ chapter.name }}: {{ chapter.desc }} <br />
        <div class="chapter">
          <div class="question" v-for="(ques, qIndex) in chapter.questionList" :key="qIndex">
            <questionViewWidget
              :tikuQuestion="ques"
              :showAnswer="true"
              :showQuestion="false"
              :qIndex="ques.questionIndex"
            ></questionViewWidget>
            <br />
          </div>
        </div>
        <br />
      </section>
    </div>
  </section>
</template>

<script>
import { tikuApi } from "@/api/tikuApi";
import msgLogger from "@/util/msgLogger.js";
import questionViewWidget from "@/component/questionViewWidget";
export default {
  components: { questionViewWidget },
  data() {
    return {
      paperModeOptions: [
        {
          value: "student",
          label: "学生",
        },
        {
          value: "teacher",
          label: "教研",
        },
      ],
      paperMode: "student",
      vo: {},
      paper: {},
      questionList: [],
    };
  },
  methods: {
    warpABCD(idx) {
      let text = String.fromCharCode(65 + idx);
      return text;
    },
    choiceAnswer(ques) {
      //return ques.correctAnswer.choice;
      let _this = this;
      let tmpList = ques.correctAnswer.choice.split(",");
      tmpList = tmpList.map((x) => _this.warpABCD(Number(x)));
      let showAns = tmpList.join(",");
      return showAns;
    },
    wrapQuestionMeta(ques) {
      let ans = "";
      if (
        ques.choiceQuestion &&
        ques.choiceQuestion.tikuQuestionStat &&
        ques.choiceQuestion.tikuQuestionStat.correctRatio &&
        ques.choiceQuestion.tikuQuestionStat.mostWrongAnswer
      ) {
        ans =
          "正确率：" +
          ques.choiceQuestion.tikuQuestionStat.correctRatio.toFixed(2) +
          "%" +
          "<br/>" +
          "易错项：" +
          this.warpABCD(Number(ques.choiceQuestion.tikuQuestionStat.mostWrongAnswer.choice)) +
          "<br />";
      }
      return ans;
    },
    wrapKeypoint(ques) {
      let ans = "";
      for (let item of ques.keypointList) {
        let tmpList = item.path.map((x) => x.name);
        let tmp = "考点：" + item.kp.name + "，" + "考点父节点：" + tmpList.join("--");
        ans += tmp + "<br/>";
      }
      return ans + "<br />";
    },
    wrapPaperSheet() {
      this.paper = this.vo.paper;
      this.questionList = this.vo.questionList;
      for (let i = 0; i < this.questionList.length; i++) {
        let solution = this.questionList[i];
        solution.questionIndex = i + 1;
        solution.questionMetaHtml = this.wrapQuestionMeta(solution);
      }
      for (let chapter of this.paper.chapters) {
        let start = chapter.start;
        let end = chapter.end;
        chapter.questionList = this.questionList.slice(start, end);
      }
      console.log("wrap", this.paper);
    },
  },
  mounted() {
    let id = 0;
    let subjectCourseId = 0;
    if (this.$route.query && this.$route.query.id) {
      id = this.$route.query.id;
    }
    if (this.$route.query && this.$route.query.subjectCourseId) {
      subjectCourseId = this.$route.query.subjectCourseId;
    }
    if (id > 0 && subjectCourseId > 0) {
      tikuApi.univSolution(subjectCourseId, id).then((ret) => {
        console.log("univ solution", ret);
        let { code: code, data: data } = ret;
        if (code == 0) {
          this.vo = data;
          this.wrapPaperSheet();
        }
      });
    }
  },
};
</script>

<style lang="less" scoped>
.paper-view {
  text-align: left;
}
</style>
